
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexSZMUTE7N5S8PFXhv_45lQq1YseU8L4ajK4FoIW3ohmR_cMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginUqOBJ5iSv1Ep4z2wTusjZx_45lSySkREPqzM1nG2Um8xcMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyCXcNyEqLqhCgKvc2fOjJ8717nUOGJaLUhsN8xUdFIS4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapujn_YzGWH63kG0tjikbeH2W3gFviF609cpf9YAo_F9YMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usOM2JnRn6f3TeD1KVzX3BbHo2HtUEz9C8Wrt3zVZea_gMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as register5xDbRMet3zm5B6k1b85_45J0xIqu15czaDlIYFXLRkL9cMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsTRZWt44w0iWHLJkZVLKE0zrJ5eqNtUGWKUq2mB5O8OUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usLDdwDs7YFE5D5eaDZ1OTK6CHjXlXgVhK_45ODrWit2F94Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexoLbJFFMgf2CL1wuicXm_BB2nBY3SYmDV_45zDggRXuwlAMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indextUwYUNqujddQoNKBOk2_0sQZqyc4KIGtNpBFlUGJBTUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93rFuaCjHa_ngCEOwesqDP_453egwLd0uNP4yQqiZbpHlhIMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsHoHph7vIZjouJ7DDrOLsLyz4URe5wt1KYhjVAQPtopoMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useIuU3_vjuNB80qY5bM07EdbQoEy83Aw2B6w8Ee2rfB4gMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as index6QYILINEKjN_45jg5dmBaboV80e8ct5AY4NLmsqOXN5IkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policy7jy6Tv5FQ9ECqhYUM4RSWHD8_4556gX2hj1hYLI1Va_i8Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordEcSEsL2phCDOvcy_7uQYS7uJo9h7MCbYfYrxfDoXLG8Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexRu_d1oupvWOPk_456zSTeWpxJ2C_FSbJlQ59jQ9goEpOcMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexSvezwp0CW6y16xXyQir0Cry6uDM_45Gof7ZLPRDqIFf7oMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password576iFRMjlCyyhb8meWMouFmEsIevl8CiSUnxz3ICgLEMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errorjNv4Sw93H9t6xoHNWKS5OZHxhN_45_45AutG9QrtxgvSkFQMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexAp_v_45hGK86PXH3NujFzFGllgZcdc2v0Nby7tSrrlKfAMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93QqVNRXYYyyIFWpWaGNoAtaP7vEbfM75DOOIDRHKVzcIMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesp8V4XLsWPHLBFH188uX_45sLGel_45ZnCWXxBwpPy_wDa4AMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeUzNbaqgivq1V0HbvKJNX81zKkDvyzknP9NWIbyg9OTMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexyGz0yA4njkiiQO4ogX3UHPTYZWVWt0LSUZvTWg0Hvi0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45password_45_zS3AHvGcXC_45buuauvU5X5X7Q2h0sXjO403p5B6P_cMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45back_45em_99jgd69j76mwyVXtPj2Q882VhauaAscoOH1ww_IMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergehiN8aERF9uFCzE0lUliwSIxAbpLnBMvg4d3VTytX1NcMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexQI4mxewsIMwMg_oHw5LvFhDryHA5xQD3bw4kMdyrXeMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93Nj_45_45Al3pazwj7kIA6EEFtefW_45AmxRgDie_ghKa28X_AMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youyisbXR8_45BbZGRJ8thXlLeM0BMM1ANagstQ0xmnPrydkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youq7uQO0facWoWNWw0HOWyTTRgTqCbKXf_45T_45io9rPx2lsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexVqHRam4AdBJP_R3zqV5aCDr6fqQVfizcKkOHs_P0wMcMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexLa2W0j7qmb3E8QOuhMiY5uJqZz8_45Kihp3v2zDfFJamUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93iX2vfqCP_459dXIEGQQz5d3MvQuUfrS8pZHgUHJ099ue0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexagX9aBSd_KhfKwGXkNjqXSwV_ZuYSjoNx_45XB7u88b9sMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as index_45WbLbB16yLEOnzsUSNt0pMdSRaLzbGmjg64Hp__4577C8Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93CEPUTu_45tmZHGmt_45QxK0rpaZugZfA7xuVhaCx_45OBraOYMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackFTPG_TwlmuWFiOYE8z3BKS8tYUwT7OnW2rB3Vss2b6cMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimdkylQyM819TvLvduCseLYPosufsDcFT8aO_45e1KzEtLUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index7LHRn3YgTXjZWtJMBuEXf9HEXWADsqHB0A3ZC5XdzGQMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youA4K3lHkycFNtiwql8yfXsZ1_44ERxBYxUtFnmN6uW2wMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as error2pVpoYaB5S2RPL2wHYsOue6_45XbCn38647yX6bG7WObEMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexBreGw4lqhwm_TEv60_45QXR_453cLHms4ciGoOsj_Ily_45rwMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indextqrQgSJ207CcTB60QJn5F9ySjKyzokX35Py0PpYVX_gMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorQe4SbTZZvDTzocKzBtMFUFjeG3bnvk0wFekHY0oc_45igMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93Q_45sFBkBmSBHiAIEU3xLZ6j_45qIPqXymvuhNb9W7nu2b4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailssjV0UDswXZ4mnIB7ILjzOje9zT2apb2EDQrzZuF7hY4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45rates6ogDtYYRVr38251kjX8HU6Qs_N3E13QNqjJoAuBtglsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93V14_45RCgOgzcE7EZxgwfE1PTWwygyVJw7JFCysNh978IMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexyZhOWnwVrGE3bj55QGHfFyj95_kQKxG6KGs7QL2RCXkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsz_45D1D0xY9h3ZqVlB0shR4utdyaNozMWZpl1rTyFDA38Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexUckOdfXv2xaTHxR4_sNAKQb3NQxcdB_45CnaF1nZSP6FYMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chateYB_45ZL9s5q9lD3EtqEVLBv5sARjd1AXAe7i7cxtrUt0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45salee8v_7Nec_45OI4_YU520pTjJdT3DyItm2dbSZE5s6W7FMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youQVFtwCiWC1K1ZVEQ2LKMbhd8eLNwXVIhalbmAWcPMfYMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensivexL9gpWOMCoKjmo_45iBq8wPjV2MWcXDs2u_8aewdwLUf4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93PxBi7jznM1mNf8d0_45mCEYmzy8zXdSK78HcKL987Ww0kMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addfGOKKL1VnCi5Kl4oX__45XbZa0dat88xOR4FU9rS2xv8EMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexhj7IFqr6HfSV9vee2TvR9PR0yd1uNoq171I2ucUUQd4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93oQ_45SLR9XpLOy0CZ2fkYX66FIoXuIlWOEV9iocsafXHUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackw6uQN11uawndk7Ngp9ozD8bIPWot_OFaBu7jayEzdDMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93Xb8lKIEy2X8BVdsca9L5Yu0HNZ141kcASbkZhuIIDRkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youXSkzv9Ic_jxXimJ7iVvm7s_45FK4fSM9L9s9YS_PWwGl8Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45biTvXQyY1rW8YjCrrPSazCTspkvfI9TW1LuDcW3Yqn6cMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexo0EmJqRt8D8sNZfUoXKpYXExoelkHn7wjXkKJEi_GbUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93zEbC5fS80b6_Gv3J4aU20eNH1uuNOzJIl3wEt2Q9Tn8Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_931r65JIOlsGJPzMKS_9CtHREvj0JFUn2v4cqgZIigfxAMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexqrNFxIs5ZF2cOopm9zRsuBWHQRdAfZN_454DoXV7tFy1EMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addei5puZrPH2_45KmtFZtMEUTnwgqEBGVylnL2R4u2ZcjfQMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93uO_45HEjsVFl7U24v6gPI3ZTrNAeiymgqiNrEdUPb4vQAMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailslweYXpDoVqhXnE1a7Kzhd5FJGl864A3OuPdgcrFcDAsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbacknVqRWfDZYLdDch9I_45n41sBR2oKQxxGhMSTFYdWue3qUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eicyshJCkIlKDkGE2BCoAIagWTYW1pCHJz_45SlGPuT6WjtMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45you_45q1gA_45NRFccFNGN7GNsusjfyBnh_Y7fiBBw7Pls29ugMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93jYuGLz9xIPp8yKwUwy4y_45kk6Bmn07GVopAhM3Z3fhJUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93SNmLCFyfpCI85LieLYCGmv8Yh5qb_7jNrlgOa9NZdjcMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonbubUq7YBR4Dz3D09VFv065v77tPsMHhYQG7m1FwTxFUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1X9_45BPQU1BWiCs8eybPdg3DFa8ULXZ3IwxOYRkRDoHBAMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as detailsl5hzsdmTz7VzMTKgVmxetcW8yXsQ8VUMPsItUcPLANIMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results_Lycx7v7iyOkk5_JazYxlw9Vnxp_45sGCy_45B5qsV6M23QMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexoRD36Ul5vVQHGHpIOj0FXlBjd4EsrqBcdzhwGfHrfeUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupFqF29tocD1pZel98sWacTox_XtKJcA_45MbTRwquqrRUcMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youmGXYogWEKI79TDgFEUTHoNOtNNUxR5VuOtUpqxzU1OEMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93idh2StIyzNtaKs5BeoknOdrCcCdGsu_7AFehDrEyUx4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93LbP8advS_NISQfytdBZ5GaV37L5fV6mvZAIkSaTxa8AMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0OLhAlQzQeQK_gv95dFQtMtlv8C4ySt63hmzgEw6UEIMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersrfGEzSMQtJLYiPg3HmNajJe9gKkYzprgDRLvdiKM_45z0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youHxf3Io7LRuOEr9r4J1qaTgxWSBV9y3E9XuZhXa46NsQMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsskRrhvpHW5RS_q111kp_dBanBcu6nfM1QaCmB1GJaIgMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as details38jXP9Kr_45KFeQqg_xDqyLr5D1rnVhTP05hs0cfpkBNkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93qryugYv_5L_45l7r5cnheymQprYMK_45trpURqKVKdZ9Dr4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebateHHq_xuKdqH1J1gh1AsIqt9rjkeCM1qvsBuupb8b1Q18Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93gHudtLb0ZpTEcX_4583_45gOkgFvPyjvL1Fpf9ytknd1zesMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsP1XS6T_454bDfLs0DCABK3sppHXqF6_zZt15_45jkXBAY4YMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0Q0oOjSJGh_gkdyRDnKp_DSg7lj_N9s16I2wPFOhbOtkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexZl85POutC_GQWp4ypHKZ3TobLdjbnKYqTSXnT2xWiOgMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexSG3Hp0yCYjgDW3nugXeWvC0ts3OGD6njym9TdZWHHXAMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as details66AARNSselpsCto1wJ7tIz8mqoN_i_45UKskW9aUfAez0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionseUNBwPZmKEOw5QK465WCrMcz7fpDg6ArtU__1qA_l58Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93UW_Ji6QZkhhVydnc9soM2KxxS2yG5QHalWI7jbgePrsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as indexWLmV7E7CmHP05xJJlo28ClhhToAS1teD15CJJRNE0HgMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsL10A9mp_TEJPa2fwwIL7GcQR_45Tyn7EKIOv9zjkbusawMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indextw2tf88FUZYw9iJvN_45fVQQNPJXV_jY1md8lAD6aKKN0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successnJLWBu5uvsUgRL3y036xKwt4_CzeLkKE_45CILiqaQueMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offer0l_JiWiGhmXuBe8mlh_hDQoldrMRf5fcS8ABONXe_DMMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsFsEAXwg5W_45lRtR_45PT5NHAgAr9iEsauE_45ijv6BfZFKR0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourZkzqfZWJthgVsy_45AYt5cVGxerQAKsfit0inkReoltdQMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93BnDjsJI_ty6q47YAi3hbiWQMpfiXMkKpaWJPQowLqT4Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexOXBH8Adu_45iSLOrRrNCyo09PvdWH8f7LORJobJ3DlpaYMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93D7_45M4z4ndH8rkjEe_gwfZRAfqinYVJgIRAmCwPPzih0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexElp9VFSOt55LQ62_45spfIpoK7_3LQcD7CFtYepL3P330Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93od8FooW1tCnZ_gNS_u_45z7KGl_MZDHXQXG3eaMWoXr7IMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45youJOudBSV3mB7Wtusn_45c86RclQqMRYGwTtokHAIDvkp9UMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexgX1Vi0Yom2LIQsz_eHPrNxC0i9e66dMB8NkDgtXax9kMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexk9e9nHAq6eQlCHy40LZDFuR9mUB9JjT6ECDiySEW4csMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsszQzNLFT_AvAwyDvMGs7s9PEH2f_45iBsMOF0_45_4530325QMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredu3as_45CJQhUUfMLSyG_UpPVyhhUE2330TWZM88RoG4WgMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexa_45bGQcx_45_Xl_45wUqXtUUYuHDXAp_45tPFBsyqF3MlzqiwsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youbKpSthpdxM0_45tZMPnmcDb3YD_CekNnnUr4FI17lYpX0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as details1L0HH0IzoLfxflHuIDPvLRS95Gjh5VyVTuxZDmQtVMkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentHv596FlOrVJVFLJJU9u8SogiCV2HNem674dGXMkwPzsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmation_45kHBZwcd1nOcqQDWOJk9JI_45rYoDhIwcUdAQ4K0AAjzYMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completions2RjgXaJ3BVzKENgrE1vWSV0FUiTdQ2bP74MiIpWEncMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsn9L0yANfd5yMC9EIcqCsvjj0bbkTju4D4HRfdljcoe0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmation57FTGMS5IuZzH9tGhrXRMBa9d7PezvEei4r36eVW1CoMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93daFhTd3s0ymVeu_k0jf8Vzj_45ldrGIJzlBkXH5OpGJF0Meta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93rEGMEJ3I3tpMdSnBWGfNp8i1kota97HuJvU_SzYbtdUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93v0ZDWPFmEUHaDxZWblZ_TclTHJW3imcs5N7291HMqDkMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93awvLice1fq1GKVTzM5FiHZG9uLAd7C8HLTPUaf80nFsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as index7qYHn99_Yazn9TJUMH8_Z1gNV1ZAAk6_45jlH5SP2DfzYMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsIEGkdW_458E3revd_45g7RUf61pV_458UP0GjWpjjnrISY1dQMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93RU_BHSxWuBEo9jflcO_OSqENMLd4C9_45obzcufv19uzAMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93ypTHENALD_j7HoHt7ro7WJL2O0mZw63OCZ6W9tOGlCUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexUwe4Wq3LB_45n7OH8QKYesh99yUu4gd1G5BD7bQhl_45uxUMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as indexSGzhqB1LfSO9HMwJ30kOQPjlYnHuHHALCPKcCxjRCAsMeta } from "/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginUqOBJ5iSv1Ep4z2wTusjZx_45lSySkREPqzM1nG2Um8xcMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register5xDbRMet3zm5B6k1b85_45J0xIqu15czaDlIYFXLRkL9cMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordEcSEsL2phCDOvcy_7uQYS7uJo9h7MCbYfYrxfDoXLG8Meta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexSvezwp0CW6y16xXyQir0Cry6uDM_45Gof7ZLPRDqIFf7oMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password576iFRMjlCyyhb8meWMouFmEsIevl8CiSUnxz3ICgLEMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45password_45_zS3AHvGcXC_45buuauvU5X5X7Q2h0sXjO403p5B6P_cMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93Nj_45_45Al3pazwj7kIA6EEFtefW_45AmxRgDie_ghKa28X_AMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexagX9aBSd_KhfKwGXkNjqXSwV_ZuYSjoNx_45XB7u88b9sMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index7LHRn3YgTXjZWtJMBuEXf9HEXWADsqHB0A3ZC5XdzGQMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indextqrQgSJ207CcTB60QJn5F9ySjKyzokX35Py0PpYVX_gMeta?.props ?? {"verificationNeeded":true},
    meta: indextqrQgSJ207CcTB60QJn5F9ySjKyzokX35Py0PpYVX_gMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsz_45D1D0xY9h3ZqVlB0shR4utdyaNozMWZpl1rTyFDA38Meta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexUckOdfXv2xaTHxR4_sNAKQb3NQxcdB_45CnaF1nZSP6FYMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackw6uQN11uawndk7Ngp9ozD8bIPWot_OFaBu7jayEzdDMMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebateHHq_xuKdqH1J1gh1AsIqt9rjkeCM1qvsBuupb8b1Q18Meta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: details66AARNSselpsCto1wJ7tIz8mqoN_i_45UKskW9aUfAez0Meta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offer0l_JiWiGhmXuBe8mlh_hDQoldrMRf5fcS8ABONXe_DMMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsFsEAXwg5W_45lRtR_45PT5NHAgAr9iEsauE_45ijv6BfZFKR0Meta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93BnDjsJI_ty6q47YAi3hbiWQMpfiXMkKpaWJPQowLqT4Meta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completions2RjgXaJ3BVzKENgrE1vWSV0FUiTdQ2bP74MiIpWEncMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93daFhTd3s0ymVeu_k0jf8Vzj_45ldrGIJzlBkXH5OpGJF0Meta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93rEGMEJ3I3tpMdSnBWGfNp8i1kota97HuJvU_SzYbtdUMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93awvLice1fq1GKVTzM5FiHZG9uLAd7C8HLTPUaf80nFsMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsIEGkdW_458E3revd_45g7RUf61pV_458UP0GjWpjjnrISY1dQMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93RU_BHSxWuBEo9jflcO_OSqENMLd4C9_45obzcufv19uzAMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93ypTHENALD_j7HoHt7ro7WJL2O0mZw63OCZ6W9tOGlCUMeta || {},
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2791506817/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]